<template>
  <div id="app">
    <div class="wrapper">
      <navigation />
      <router-view />
      <custom-cursor />
    </div>
  </div>
</template>

<script>
import CustomCursor from "@/components/Cursor";
const Navigation = () => import(/* webpackChunkName: "Navigation" */ "@/components/Navigation");
export default {
  components: {
    Navigation,
    CustomCursor
  },
  beforeCreate() {
    this.$store.dispatch("loadContent").then(() => {
        // console.log("site ✅");
    });
    this.$store.dispatch("loadYears").then(() => {
        // console.log("projects ✅");
        this.$root.$emit("projects-loaded");
    });
    this.$store.dispatch("loadAbout").then(() => {
        // console.log("about ✅");
        this.$root.$emit("about-loaded");
    });
  },
  computed: {
    site() {
      return this.$store.state.site;
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.emitSize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.emitSize);
  },
  methods: {
    emitSize() {
      this.$root.$emit("window-resized");
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/reset-css/sass/_reset";
* {
  box-sizing: border-box;
  cursor: none !important;
    @media screen and (max-width: 768px) {
        cursor: auto !important;
        
    }
}

@font-face {
    font-family: "Libre Caslon Display";
    font-display:swap;
    font-style: normal;
    src: url(/dist/fonts/LibreCaslonDisplay-Regular.ttf) format("truetype");
}

::selection {
  background-color: rgb(0, 0, 0);
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

a,
a:active,
a:hover,
a:visited {
  color: black;
  text-decoration: none;
  transition: color 0.5s;
  &:hover {
    font-style: italic;
    // color: #aaa;
  }
}

body,
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --fs: 1.3vw;
  --lh: calc(1.25 * 1.3vw);
  @media screen and (max-width: 1024px) {
    --fs: 2vw;
    --lh: calc(1.25 * 2vw);
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    --fs: 2vw;
    --lh: calc(1.25 * 2vw);
  }
  @media screen and (max-width: 768px) and (orientation: portrait) {
    --fs: 5vw;
    --lh: calc(1.25 * 5vw);
  }
  font-size: var(--fs);
  line-height: var(--lh);
}
#app {
  //font-family: "Big Caslon", sans-serif;
    font-family: "Libre Caslon Display", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fs);
  line-height: var(--lh);
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: top 0.5s;
    min-height:100vh;
    background:white;
    &.off {
      transition: top 0.5s;
      top: 0 !important;
    }
  }
  main {
    background: white;
    min-height: 50%;
    margin-top:3rem;
    padding: calc(1 * var(--lh));
    @media only screen and (max-width:768px) {
        margin-top:8rem;
    }
  }
  li,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .title {
    // font-family: "GoldmanItalic", sans-serif;
    // font-style: normal;
    font-style: italic;
  }
  .italic,
  em {
    // font-family: "GoldmanItalic", sans-serif;
    // font-style: normal;
    font-style: italic;
  }
  strong {
    font-weight: 700;
  }
  .smallcaps {
    font-feature-settings: "c2sc", "smcp";
    font-variant: all-small-caps;
    // text-transform: uppercase;
    text-transform: lowercase;
  }
  img {
    transition: opacity 0.25s;
  }
  img[lazy="loading"] {
    opacity: 0;
  }
  img[lazy="loaded"] {
    opacity: 1;
  }
}
</style>
