<template>
    <div v-show="!hide" class="cursor" :style="{ left: `${x}px`, top: `${y}px` }">
        <div class="cursor__inner"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                x: 0,
                y: 0,
                hide: false
            };
        },
        mounted() {
            window.addEventListener("mousemove", this.trackMouse);
            document.addEventListener("mouseout", () => {
                this.hide = true;
            });
        },
        beforeDestroy() {
            window.removeEventListener("mousemove", this.trackMouse);
            document.removeEventListener("mouseout", () => {
                this.hide = true;
            });
        },
        methods: {
            trackMouse(e) {
                this.hide = false;
                this.x = e.clientX;
                this.y = e.clientY;
            }
        }
    };
</script>

<style>
.cursor {
    position: fixed;
    z-index: 1000;
    pointer-events: none;
    mix-blend-mode: difference;
    @media only screen and (max-width: 768px) {
        display: none;
    }
}
.cursor__inner {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}
</style>