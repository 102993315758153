import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    site: [],
    pages: [],
    projecttypes: [],
    abc: [],
    year: [],
    settings: false,
    settingsHeight: 0,
    playStatus: false,
    sort: "year",
    current: true,
    info: {
      bio: true,
      contact: true,
      newsletter: true,
      news: true,
      press: true,
      impressum: true,
      solo_exhibitions: true,
      group_exhibitions: true,
      performances: true,
      filmography: true,
      bibliography: true,
      publications: true,
      awards: true,
      residencies: true,
      collectives: true,
      teaching: true,
      writing: true,
      other: true,
    },
    categories: {
      solo: true,
      group: true,
      performance: true,
      writing: true,
      talks: true,
      related: true,
    },
    types: {
      images: true,
      video: true,
      audio: true,
      documents: true,
      texts: true,
    },
  },
  getters: {
    getPagesByType: (state, getters) => (type, collection = state.pages) => {
      let results = [];
      if (!collection || !collection.length) {
        return results;
      }
      for (let i = 0; i < collection.length; i++) {
        if (collection[i].type === type) {
          results.push(collection[i]);
        }
        let targetPages = getters.getPagesByType(type, collection[i].children);
        results = results.concat(targetPages);
      }
      return results;
    },
    getPageByUID: (state, getters) => (uid, collection = state.pages) => {
      let i = collection.length;
      while (i--) {
        if (collection[i].uid === uid) {
          return collection[i];
        }
        let targetPage = getters.getPageByUID(uid, collection[i].children);
        if (targetPage) {
          return targetPage;
        }
      }
    },
  },
  actions: {
    async loadContent({ commit }) {
        await axios.post('/api/query', {
            query: "site",
            select: {
                title: true,
                url: true,
                current: "site.current_md"
            }
        }, {
            auth: {
                username: process.env.VUE_APP_USERNAME,
                password: process.env.VUE_APP_PASSWORD,
            },
        }).then(function (response) {
            commit("SET_SITE", response.data.result);
        });
    },
    async loadAbout({ commit }) {
        await axios.post('/api/query', {
            query: "page('info')",
            select: {
                title: true,
                url: true,
                status: true,
                index: "page.num",
                template: "page.intendedTemplate",
                text: "page.text_md.kt",
                contact: "page.contact_md.kt",
                impressum: "page.impressum_md.kt",
                press: {
                    query: "page.press.toStructure",
                    select: {
                        year: "structureItem.year",
                        title: true,
                        type: true,
                        link: true,
                        file: {
                            query: "structureItem.file.toFile",
                            select: {
                                url: true,
                                extension: true
                            }
                        }
                        // file: true
                    }
                },
                cv: {
                    query: "page.cv.toStructure",
                    select: {
                        year: true,
                        type: true,
                        title: "structureItem.title.kt"
                    }
                }
            }
        }, {
            auth: {
                username: process.env.VUE_APP_USERNAME,
                password: process.env.VUE_APP_PASSWORD,
            },
        }).then(function (response) {
            commit("SET_PAGES", response.data.result);
        });
    },
    async loadYears({ commit }) {
        await axios.post('/api/query', {
            query: "page('projects').children.listed",
            select: {
                title: true,
                uid: true,
                num: 'page.num',
                year: true,
                description: "page.description_md",
                files: {
                    query: "page.files.sortBy('sort')",
                    select: {
                        url: true,
                        thumb: "file.resize(100).url",
                        srcset: "file.srcset([200, 400, 800])",
                        extension: true,
                        index: "file.indexOf",
                    }
                }
            }
        }, {
            auth: {
                username: process.env.VUE_APP_USERNAME,
                password: process.env.VUE_APP_PASSWORD,
            },
        }).then(function (response) {
            commit("SET_YEAR", response.data.result);
        });
    },
  },
  mutations: {
    SET_SITE(state, site) {
      state.site = site;
    },
    SET_PAGES(state, pages) {
      state.pages = pages;
    },
    SET_YEAR(state, pages) {
      state.year = pages;
    },
  },
});
