import Vue from "vue";
import Router from "vue-router";
const Home = () => import(/* webpackChunkName: "Home" */ "./views/Home.vue");
const Project = () =>
  import(/* webpackChunkName: "Project" */ "./views/Project.vue");
const Info = () => import(/* webpackChunkName: "Info" */ "./views/About.vue");

Vue.use(Router);

export default new Router({
  mode: "history",
  // scrollBehavior() {
  // return { x: 0, y: 0 };
  // },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      children: [
        {
          name: "project",
          path: "projects/:id",
          component: Project,
        },
      ],
    },
    {
      path: "/info",
      name: "info",
      component: Info,
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});
